import React from "react"
import Layout from "../components/layout"
import './termsAndPolicy.scss'


const Health = () => {

  return (
    <Layout>
      <div className="wrapper-section-terms-policy">
        <h1>TERMO DE COLETA E TRATAMENTO DE DADOS SENSÍVEIS ("Coleta")</h1>
        <p><i>Data da última atualização: 23 de novembro de 2020</i></p>
        <p>Bom, para ter chegado até este termo de Coleta, você deve ter
          lido os Termos e a Política, documentos nos quais não há menção
          à Coleta. <u>Caso você não tenha lido os Termos e a Política, você
          deve lê-los antes de continuar e aceitar a Coleta</u>. Os links de
          acesso para eles estão disponíveis em&nbsp;
          <a className="cookies-link" href="https://theia.com.br/terms/" target="_blank">
          https://theia.com.br/terms/
          </a> e <a className="cookies-link" href="https://theia.com.br/policy/" target="_blank">
          https://theia.com.br/policy/
          </a>
        </p>

        <p>
          Este documento, na verdade, <b><u>é uma segunda confirmação, apartada, para
          que não haja dúvidas do seu consentimento dado com o tratamento de
          dados sensíveis, em especial os dados de saúde, dados médicos e de
          vida sexual</u></b> (por exemplo, informações sobre gestação podem ser
          considerados dados de vida sexual), <b><u>bem como os dados de
          dependentes menores de idade</u></b>.
        </p>

        <p>
          Ao concordar com a Coleta, você declara, <b><u>incondicionalmente, que entende,
          concorda e consente com a coleta, tratamento e compartilhamento dos dados
          sensíveis de saúde, dados médicas, de vida sexual, e de dependentes menores
          de idade (crianças e adolescentes com menos de 18 anos)</u></b>, e entende, concorda
          e consente que compreendeu, na totalidade:
        </p>

        <ol className="list-number">
          <li>
            <b>A listagem de dados sensíveis descritos no item 4 da <a className="cookies-link" href="https://theia.com.br/policy/" target="_blank">
              Política de Privacidade
            </a></b>, em especial conforme descrito nos subitens (ii) e (iii) do item 4;
          </li>
          <li>
            <b>As finalidades de uso e casos passíveis de compartilhamento listados no
            item 3 da <a className="cookies-link" href="https://theia.com.br/policy/" target="_blank">Política de Privacidade
            </a></b>, em especial com o embasamento legal de execução do acordo
            firmado com a Theia, cumprimento de obrigações legais e tutela de saúde;
          </li>
          <li>
            <b>Seus direitos, conforme item 9 da <a className="cookies-link" href="https://theia.com.br/policy/" target="_blank">
            Política de Privacidade</a>.</b>
          </li>
        </ol>

        <p><b>
          Caso você tenha selecionado que concorda com os Termos e a Política,
          mas não concorda com a Coleta, então, de fato, você não concorda com a
          Política e não poderá, então, utilizar nossos Serviços.
        </b></p>

        <p>
          Na eventualidade de você ter dúvidas, questionamentos ou
          queira solicitar a revogação do seu consentimento para o
          tratamento de dados, envie um <i>e-mail</i> para dpo@theia.com.br.
        </p>
      </div>
    </Layout>
  )
}

export default Health






